main.main__container {
    width: 100%;
    max-width: 750px;
    margin-bottom: 2.5rem;

    .section__name {
        visibility: hidden;
        opacity: 0;
    }

    .grid__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        gap: 1.5rem;

        .main__image {
            width: 100px;
            height: 100px;
            outline: 5px solid $border;
            border-radius: 9999px;
            background-image: url("../../assets/profile.png");
            background-position: top;
            background-repeat: no-repeat;
            background-size: cover;
        }

        .main__name {
            font-family: "GeistSans";
            font-weight: 900;
            font-size: 1.875rem;
            line-height: 2.25rem;
            letter-spacing: -.025em;
            color: $foreground;
        }
    }
}

body.dark {
    main.main__container {
        .main__image {
            outline: 5px solid $dark-border;
        }
        .main__name {
            color: $dark-foreground;
        }
    }
}