/* Colors */

// Light
$background: hsl(200 5% 91);
$foreground: hsl(0 0% 3.9);
$card: hsl(200 5% 85);
$card-foreground: hsl(0 0% 3.9);
$popover: hsl(200 5% 85);
$popover-foreground: hsl(0 0% 3.9);
$muted: hsl(200 5% 89);
$muted-foreground: hsl(200 5% 34);
$primary: hsl(0 0% 9);
$primary-foreground: hsl(0 0% 98);
$secondary: hsl(0 0% 96.1);
$secondary-foreground: hsl(0 0% 9);
$accent: hsl(0 0% 96.1);
$accent-foreground: hsl(0 0% 9);
$destructive: hsl(0 84.2% 60.2);
$destructive-foreground: hsl(0 0% 98);
$border: hsl(200 5% 79);
$input: hsl(200 5% 79);
$ring: hsl(200 5% 79);
$chart-1: hsl(12 76% 61);
$chart-2: hsl(173 58% 39);
$chart-3: hsl(197 37% 24);
$chart-4: hsl(43 74% 66);
$chart-5: hsl(27 87% 67);

// Dark
$dark-background: hsl(180, 4%, 11%);
$dark-foreground: hsl(0 0% 98%);
$dark-card: hsl(200 5% 15%);
$dark-card-foreground: hsl(0 0% 98%);
$dark-popover: hsl(200 5% 15%);
$dark-popover-foreground: hsl(0 0% 98%);
$dark-muted: hsl(200 5% 16%);
$dark-muted-foreground: hsl(0 0% 56%);
$dark-primary: hsl(0 0% 98%);
$dark-primary-foreground: hsl(0 0% 9%);
$dark-secondary: hsl(0 0% 14.9%);
$dark-secondary-foreground: hsl(0 0% 98%);
$dark-accent: hsl(0 0% 14.9%);
$dark-accent-foreground: hsl(0 0% 98%);
$dark-destructive: hsl(0 62.8% 30.6%);
$dark-destructive-foreground: hsl(0 0% 98%);
$dark-border: hsl(200 5% 21%);
$dark-input: hsl(200 5% 21%);
$dark-ring: hsl(200 5% 21%);
$dark-chart-1: hsl(220 70% 50%);
$dark-chart-2: hsl(160 60% 45%);
$dark-chart-3: hsl(30 80% 55%);
$dark-chart-4: hsl(280 65% 60%);
$dark-chart-5: hsl(340 75% 55%);


$gradient-from: #a3a3a3;
$gradient-to: #404040;
$gradient-stops: $gradient-from, $gradient-to;
$ring-offset-shadow: 0 0 0 1px $background;

$dark-gradient-from: #e5e5e5;
$dark-gradient-to: #737373;
$dark-gradient-stops: $dark-gradient-from, $dark-gradient-to;
$dark-ring-offset-shadow: 0 0 0 1px $dark-background;