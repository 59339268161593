/* Responsive */
@media only screen and (max-width: 768px) {
    .container__grid {
        padding: 0 1rem;
    }
    section.section__container .grid__list {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

@media only screen and (max-width: 576px) {
    .container__grid {
        padding: 0 1.25rem;
        grid-template-columns: auto;
        .grid__section .section__name {
            text-align: left;
            margin-top: 0;
        }
    }
}