/* Typography */
@font-face {
  font-family: GeistMono;
  src: url("../../assets/fonts/webfonts/GeistMono-Regular.woff2") format("woff2");
  font-display: swap;
  font-weight: 100 900
}

@font-face {
  font-family: GeistSans;
  src: url("../../assets/fonts/webfonts/Geist-Regular.woff2") format("woff2");
  font-display: swap;
  font-weight: 400
}

@font-face {
  font-family: GeistSans;
  src: url("../../assets/fonts/webfonts/Geist-SemiBold.woff2") format("woff2");
  font-display: swap;
  font-weight: 700
}

@font-face {
  font-family: GeistSans;
  src: url("../../assets/fonts/webfonts/Geist-Bold.woff2") format("woff2");
  font-display: swap;
  font-weight: 900
}

$geist-sans: "GeistSans","GeistSans Fallback";
$geist-mono: "GeistMono",ui-monospace,SFMono-Regular,Roboto Mono,Menlo,Monaco,Liberation Mono,DejaVu Sans Mono,Courier New,monospace;
$primary-font: $geist-sans,ui-sans-serif,system-ui,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";